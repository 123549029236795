import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize, FormSection, formValueSelector, change, FieldArray } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetWedding,
  UpdateWedding,
  CreateWedding,
  CheckWeddingDate,
  RecalculateWeddingPaymentPhases,
  UpdateCreateWeddingGuest,
  NotifyWaitingListByWedding
} from '../../infra/requests/WeddingRequests';
import { getOrganizers } from '../../infra/requests/AdminsRequests';
import TextInput from '../../components/inputs/TextInput';
import NumberInput from '../../components/inputs/NumberInput';
import DateInput from '../../components/inputs/DateInput';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import SelectInput from '../../components/inputs/SelectInput';
import SelectLanguageInput from '../../components/inputs/SelectLanguageInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import DateWarningModal from './DateWarningModal';
import moment from 'moment';
import Alerts from '../../components/alert/Alert';
import { getUserId } from '../../infra/helpers/Helpers';
import XpertGoDateInput from '../../components/inputs/XpertGoDateInput';
import PaymentPhasesComponent from '../../components/paymentPhases/PaymentPhasesComponent';
import styledComponents from 'styled-components';
import debounce from 'lodash.debounce';
import WeddingGuestModal from './WeddingGuestModal';
import ModalNotifyWaitingList from './NotifyWaitingListModal';

export const RelationToCouple = {
  THIRD_USER: 'TU',
  FAMILIAR_FRIEND: 'FF',
  WEDDING_PLANNER: 'WP',
  RESERVE_SINALIZATION: 'RS'
}

const RowPaymentTitle = styledComponents.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: left;
  margin-top: ${({ subsection }) => (subsection ? '50px' : 0)};
`;

const validations = values => {
  let errors = {};
  errors = FormValidator.make({
    date: 'required',
    wedding_place: 'required',
    price: 'required',
    min_people: 'required|minNumber:140',
    language: 'required',
    dateSecondPaymentPhase: 'required',
    blockDate: 'required',
  })(values);

  errors.bride = FormValidator.make({
    name: 'required',
    email: 'required|email'
  })(values?.bride);

  errors.groom = FormValidator.make({
    name: 'required',
    email: 'required|email'
  })(values?.groom);

  if (values?.bride?.email && values?.groom?.email && values?.bride?.email.toLowerCase().trim() === values?.groom?.email.toLowerCase().trim()) {
    errors.bride.email = 'REGISTER_ERROR_SAME_EMAIL';
    errors.groom.email = 'REGISTER_ERROR_SAME_EMAIL';
  }

  const guestValidations = {};
  // If exists guest email info, then name is required
  if (values?.alternativeGuest?.name && values?.alternativeGuest?.name !== '') {
    guestValidations['email'] = 'required';
  }
  if (values?.alternativeGuest?.email && values?.alternativeGuest?.email !== '') {
    guestValidations['name'] = 'required';
  }
  if ((values?.alternativeGuest?.name && values?.alternativeGuest?.name !== '')
    || (values?.alternativeGuest?.email && values?.alternativeGuest?.email !== '')) {
    guestValidations['relationToCouple'] = 'required';
  }
  errors.alternativeGuest = FormValidator.make(guestValidations)(values?.alternativeGuest);

  if (values?.alternativeGuest?.email && (
    (values?.bride?.email && values?.bride?.email.toLowerCase().trim() === values?.alternativeGuest?.email.toLowerCase().trim()) ||
    (values?.groom?.email && values?.groom?.email.toLowerCase().trim() === values?.alternativeGuest?.email.toLowerCase().trim()))
  ) {
    errors['alternativeGuest.email'] = 'ALTERNATIVE_GUEST_ERROR_SAME_EMAIL';
  }

  return errors;
};

class CreateWeddingPage extends Component {
  state = {
    isNew: true,
    loading: true,
    organizers: [],
    wedding: null,

    weddingInfo: {},
    showDateModal: false,
    loadingButton: false,
    warningMessage: 'Já existe um casamento para a data e local selecionados!',

    recalculating: false,

    showGuestModal: false,
    savingGuest: false,

    openModalNotify: false,
    originalWeddingInfo: null
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
    } = this.props;

    const { data } = await getOrganizers();

    if (params.id) {
      const result = await GetWedding(params.id);
      if (result?.success && result?.data && !result?.data?.dateSecondPaymentPhase) {
        result.data.dateSecondPaymentPhase = moment(result.data.date).isValid()
          ? moment(result.data.date).subtract(1, 'year').subtract(15, 'days')
          : null;
      }

      dispatch(
        initialize('manage_wedding_form', {
          ...result?.data,
          organizer: result?.data?.organizer?._id
        })
      );
      this.props.dispatch(change('manage_wedding_form','confirmationDate',result.data.confirmationDate));
      this.setState({
        isNew: false,
        loading: false,
        organizers: data,
        wedding: result?.success && result?.data ? result.data : null,
        originalWeddingInfo: result?.success && result?.data ? result.data : null,
      });
    } else {
      dispatch(
        initialize('manage_wedding_form', {
          min_people: 140,
        })
      );
      this.setState({
        isNew: true,
        loading: false,
        organizers: data,
      });
    }
  };

  onSubmit = async (values, saveType) => {
    try {
      this.setState({ loadingButton: true });
      const {
        match: { params },
        history
      } = this.props;
      const { isNew } = this.state;

      //Type "2" means we already showed the modal with the warning so we save the Wedding anyway
      let dateAvailable = true;
      let companyRest = false;
      let reserve = false;
      let reservePrice = null;

      if (saveType === 1) {
        const checkAvailable = await this.checkWeddingDate();
        // console.log('%cCreateWeddingPage.js line:147 checkAvailable', 'color: #007acc;', checkAvailable);
        dateAvailable = checkAvailable.dateAvailable;
        companyRest = checkAvailable.companyRest;
        reserve = checkAvailable.reserve;
        reservePrice = checkAvailable.reservePrice;
      }

      // Date is available, save the Wedding
      if (dateAvailable && !companyRest && !reserve) {
        if (reservePrice?.minPax !== values.min_people || reservePrice?.minPrice !== values.price) {
          Alerts.new({
            type: 'warning',
            title: 'Atenção',
            text: 'O preço e o número mínimo de pessoas para este casamento serão alterados com base na agenda de reservas!'
          });
        }

        const weddingDate = moment.utc(values.date).startOf('day');
        values.date = weddingDate.toISOString();
        if (isNew) values['user'] = getUserId();

        const { data, success } = isNew
          ? await CreateWedding(values)
          : await UpdateWedding(params.id, values);
        if (success) {
          if (!isNew) {
            const isSameDate = data?.wedding?.date && values?.date && moment.utc(data.wedding.date).isSame(moment.utc(values?.date), 'day')
              ? true
              : false;

            const isSamePlace = values?.wedding_place && data?.wedding?.wedding_place
              && values?.wedding_place == data?.wedding?.wedding_place
              ? true
              : false;
            if (weddingDate && weddingDate.isAfter(moment.utc(), 'date')
              && (!isSameDate || !isSamePlace)) {
              this.setState({ openModalNotify: true });
            } else {
              this.handleOnCancel();
            }
          } else {
            this.handleOnCancel();
          }
        }
        this.setState({ loadingButton: false });
      }
      // Date isn't available because of reserves in status 'In Sinalization' or 'Date Occupied'
      else if (!dateAvailable && !companyRest && reserve) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'Data com Reservas Em Sinalização ou Confirmadas!'
        });
        this.setState({ loadingButton: false });
      }
      // Date is available, but it's a rest day
      else if (companyRest) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'Dia Indisponível ou de Descanso'
        });
        this.setState({ loadingButton: false });
      }
      //Date is not available (if reservers in status 'In Sinalization' or 'Date Occupied' or has more weddings for same date/place), show warning
      else {
        let warningMessage = this.state.warningMessage;
        // Date isn't available because of reserves in status 'In Sinalization' or 'Date Occupied'
        if (!dateAvailable && !companyRest && reserve) {
          warningMessage = 'Existem reservas Em Sinalização ou Confirmadas para essa data e local!';
        }
        this.setState({ loadingButton: false, showDateModal: true, warningMessage });
      }
    }
    catch (e) {
      console.error(e);
      this.setState({ loadingButton: false });
    }
  };

  handleOnCancel = async () => {
    const { history, location } = this.props;
    const fromReserveAgenda = new URLSearchParams(location.search).get('page') === 'RA' ? true : false;
    const fromReservePriceAgenda = new URLSearchParams(location.search).get('page') === 'RP' ? true : false;
    const fromReserve = new URLSearchParams(location.search).get('page') === 'R' ? true : false;
    const fromWaitingDateList = new URLSearchParams(location.search).get('page') === 'WD' ? true : false;
    const fromInterestDateList = new URLSearchParams(location.search).get('page') === 'ID' ? true : false;
    const fromVisitSchedules = new URLSearchParams(location.search).get('page') === 'VS' ? true : false;
    const fromMeetingSchedules = new URLSearchParams(location.search).get('page') === 'MS' ? true : false;

    if (fromReservePriceAgenda) return history.push('/reserves-prices');
    else if (fromReserve || fromReserveAgenda) return history.push('/reserves');
    else if (fromWaitingDateList) return history.push('/waiting-dates');
    else if (fromInterestDateList) return history.push('/interest-dates');
    else if (fromVisitSchedules) return history.push('/visits');
    else if (fromMeetingSchedules) return history.push('/meetings');
    else return history.push('/weddings');
  };

  checkWeddingDate = async () => {
    const { match: { params }, weddingDate, weddingPlace } = this.props;
    const { isNew } = this.state;

    const data = {
      date: moment.utc(weddingDate).startOf('day').toISOString(),
      wedding_place: weddingPlace,
      wedding: isNew ? undefined : params.id
    };
    const result = await CheckWeddingDate(data);
    return result?.data;
  };

  closeDateModal = () => {
    this.setState({
      showDateModal: false
    });
  }

  recalculatePaymentPhases = async () => {
    const {
      match: { params },
      dispatch,
      weddingForm
    } = this.props;

    if (!params?.id) {
      return;
    }

    this.setState({ recalculating: true });

    const result = await RecalculateWeddingPaymentPhases(params.id, weddingForm);

    if (result.success) {
      const data = result.data;
      data.date = weddingForm.date;
      data.wedding_place = weddingForm.wedding_place;
      data.organizer = weddingForm.organizer;
      data.coordinator = weddingForm.coordinator;
      data.price = weddingForm.price;
      data.min_people = weddingForm.min_people;
      data.language = weddingForm.language;
      data.dateSecondPaymentPhase = weddingForm.dateSecondPaymentPhase;
      data.blockDate = weddingForm.blockDate;
      data.contractDate = weddingForm.contractDate;
      data.bride = weddingForm.bride;
      data.groom = weddingForm.groom;
      data.createdAt = weddingForm.createdAt;
      data.confirmationDate = weddingForm.confirmationDate;

      // Update only paymentPhases on form
      dispatch(
        initialize('manage_wedding_form', {
          ...data,
        })
      );
    }

    this.setState({ recalculating: false });
  }

  updatePaymentPhases = debounce(async (e: any) => {
    await this.recalculatePaymentPhases();
  }, 500);

  submitWeddingGuest = async (values: any) => {
    const {
      match: { params },
      dispatch,
      weddingForm
    } = this.props;

    if (!params?.id) {
      return;
    }

    this.setState({ savingGuest: true });

    const dataInfo = {
      sendEmail: true,
      guest: values?.guest,
      guestExisted: values?.guestExisted,
      name: values?.guestName,
      email: values?.guestEmail,
    }

    const { success, data } = await UpdateCreateWeddingGuest(params.id, dataInfo);

    if (success && data) {
      // Update only alternativeGuest on form
      dispatch(
        initialize('manage_wedding_form', {
          ...weddingForm,
          alternativeGuest: data?.alternativeGuest,
        })
      );
      this.setState({ savingGuest: false, showGuestModal: false });
    } else {
      this.setState({ savingGuest: false });
    }
  }

  notifyWaitingList = async (password) => {
    const {
      match: { params },
      history
    } = this.props;
    const { originalWeddingInfo } = this.state;

    if (params.id) {
      const values = {
        email: JSON.parse(localStorage.user).email,
        password,
        original: originalWeddingInfo
      };
      const { data, success } = await NotifyWaitingListByWedding(params.id, values);
      if (success) this.handleOnCancel();
      else {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: data
        });
      }
    }
  };

  render() {
    const { match: { params } } = this.props;
    const { handleSubmit, locals, invalid, submitFailed } = this.props;
    const { weddingDateSecond, weddingDateBlock } = this.props;
    const { isNew, loading, organizers, showDateModal, loadingButton, warningMessage } = this.state;
    const { recalculating } = this.state;
    const { wedding, showGuestModal, savingGuest } = this.state;
    const { originalWeddingInfo, openModalNotify } = this.state;

    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar Casamento' : 'Editar Casamento';

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit((values) => this.onSubmit(values, 1))}
              loading={loadingButton}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            {/* <Field
              component={TextInput}
              name={'createdAt'}
              type="hidden"
            /> */}
            <Field
              component={DateInput}
              name="date"
              label="Data *"
              placeholder="Data do Casamento"
              onChange={(date) => {
                const dateSecond = moment.utc(date).isValid()
                  ? moment.utc(date).subtract(1, 'year').subtract(15, 'days')
                  : null;
                const dateBlock = moment.utc(date).isValid()
                  ? moment.utc(date).subtract(30, 'days')
                  : null;
                this.props.change('dateSecondPaymentPhase', dateSecond);
                this.props.change('blockDate', dateBlock);
                if (moment.utc(date).isValid()) this.updatePaymentPhases();
              }}
            />
            <Field
              component={SelectInput}
              name={'wedding_place'}
              label={'Local *'}
              placeholder={'Escolha o Local'}
              type="select"
              data={locals}
              dataKey={'_id'}
              dataLabel={'name'}
            />
            <Field
              component={SelectInput}
              name={'organizer'}
              label={'Organizador'}
              placeholder={'Selecionar organizador'}
              type="select"
              data={organizers}
              dataKey={'_id'}
              dataLabel={'name'}
            />
            <Field
              component={TextInput}
              name={'coordinator'}
              type="text"
              label={'Coordenador'}
              placeholder={'Insira o nome do coordenador'}
            />
            <Field
              component={CurrencyInput}
              name={'price'}
              label={'Preço por PAX *'}
              placeholder={'Insira o preço por PAX'}
            />
            <Field
              component={NumberInput}
              min={140}
              onInput="validity.valid || (value = '')"
              name={'min_people'}
              label={'Minimo de Pessoas'}
              placeholder={'Insira o numero minimo de pessoas'}
            />
            <Field
              component={SelectLanguageInput}
              name={'language'}
              label={'Idioma de Comunicação *'}
              placeholder={'Selecionar idioma para comunicações da plataforma'}
            />
            <XpertGoDateInput
              label='Data de Comunicação 2º Fase de Pagamento *'
              placeholder='Data de Comunição'
              input={{
                value: weddingDateSecond,
                onChange: (event) => {
                  this.props.change('dateSecondPaymentPhase', event);
                }
              }}
              meta={{
                invalid: (weddingDateSecond === null || weddingDateSecond === undefined) && invalid,
                submitFailed: (weddingDateSecond === null || weddingDateSecond === undefined) && submitFailed
              }} />
            <XpertGoDateInput
              label='Data de Bloqueio (Noivos) *'
              placeholder='Data de Bloqueio'
              input={{
                value: weddingDateBlock,
                onChange: (event) => {
                  this.props.change('blockDate', event);
                }
              }}
              meta={{
                invalid: (weddingDateBlock === null || weddingDateBlock === undefined) && invalid,
                submitFailed: (weddingDateBlock === null || weddingDateBlock === undefined) && submitFailed
              }} />
            <Field
              component={DateInput}
              name="contractDate"
              label="Data do Contrato"
              placeholder="Data do Contrato"
              //onChange={(date) => this.updatePaymentPhases()}
            />

            {/* Payment Phases */}
            {params?.id && <React.Fragment>
              <RowPaymentTitle subsection>
                <SectionTitle>Fases de Pagamento</SectionTitle>
                <BaseButton
                  type="primary"
                  icon="calculator"
                  text="Repôr Datas"
                  onClick={this.recalculatePaymentPhases}
                  loading={recalculating}
                />
              </RowPaymentTitle>
              <FieldArray component={PaymentPhasesComponent} name="paymentPhases" />
            </React.Fragment>}

            <SectionTitle subsection>Dados Noiva/Noivo</SectionTitle>
            <FormSection name="bride">
              <Field
                component={TextInput}
                name={'name'}
                type="text"
                label={'Nome da Noiva *'}
                placeholder={'Insira o nome noiva/noivo'}
              />
              <Field
                component={TextInput}
                name={'email'}
                type="email"
                label={'Email da Noiva *'}
                placeholder={'Email de acesso noiva/noivo'}
              />
            </FormSection>
            <SectionTitle subsection>Dados Noiva/Noivo</SectionTitle>
            <FormSection name="groom">
              <Field
                component={TextInput}
                name={'name'}
                type="text"
                label={'Nome do Noivo *'}
                placeholder={'Insira o nome noiva/noivo'}
              />
              <Field
                component={TextInput}
                name={'email'}
                type="email"
                label={'Email do Noivo *'}
                placeholder={'Email de acesso noiva/noivo'}
              />
            </FormSection>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between' }}>
              <SectionTitle subsection>Dados Convidado</SectionTitle>
              {!isNew && <BaseButton
                align="initial"
                htmlType="button"
                type='default'
                loading={savingGuest}
                text='Alterar ou Criar Convidado'
                style={{ marginTop: 40 }}
                onClick={() => this.setState({ showGuestModal: true })}
              />}
            </div>
            <FormSection name="alternativeGuest">
              <Field
                component={TextInput}
                name={'name'}
                type="text"
                label={'Nome do Convidado'}
                placeholder={'Insira o nome convidado'}
                disabled={savingGuest}
              />
              <Field
                component={TextInput}
                name={'email'}
                type="email"
                label={'Email do Convidado'}
                placeholder={'Email de acesso convidado'}
                disabled={!isNew || savingGuest}
              />
              <Field
                component={SelectInput}
                name={'relationToCouple'}
                type={'select'}
                disabled={false}
                label={'Relação do convidado com o casal'}
                dataKey={'value'}
                dataLabel={'name'}
                data={[
                  { value: RelationToCouple.THIRD_USER, name: '3º email do casal' },
                  { value: RelationToCouple.FAMILIAR_FRIEND, name: 'Familiar e/ou amigo' },
                  { value: RelationToCouple.WEDDING_PLANNER, name: 'Wedding Planner' },
                ]}
              />
            </FormSection>
          </BaseForm>
        </FormContainer>
        <DateWarningModal
          openModal={showDateModal}
          closeModal={this.closeDateModal}
          onSubmit={handleSubmit((values) => this.onSubmit(values, 2))}
          loading={loadingButton}
          initialValues={warningMessage}
        />

        {showGuestModal && <WeddingGuestModal
          isOpen={showGuestModal}
          saving={savingGuest}
          onClose={() => this.setState({ showGuestModal: false })}
          onSubmit={(values) => this.submitWeddingGuest(values)}
          initialValues={wedding}
        />}

        {openModalNotify && <ModalNotifyWaitingList
          isOpen={openModalNotify}
          onSubmit={($password) => this.setState({ openModalNotify: false, }, async () => await this.notifyWaitingList($password))}
          onClose={() => this.setState({ openModalNotify: false })}
        />}
      </React.Fragment>
    );
  }
}

CreateWeddingPage = reduxForm({
  form: 'manage_wedding_form',
  validate: validations
})(CreateWeddingPage);

const selector = formValueSelector('manage_wedding_form');

const mapStateToProps = state => ({
  locals: state.locals,
  // initialValues: { language: 'pt' },
  weddingDate: selector(state, 'date'),
  weddingPlace: selector(state, 'wedding_place'),
  weddingDateSecond: selector(state, 'dateSecondPaymentPhase'),
  weddingDateBlock: selector(state, 'blockDate'),
  weddingForm: {
    createdAt: selector(state, 'createdAt'),
    confirmationDate: selector(state, 'confirmationDate'),
    date: selector(state, 'date'),
    wedding_place: selector(state, 'wedding_place'),
    organizer: selector(state, 'organizer'),
    coordinator: selector(state, 'coordinator'),
    price: selector(state, 'price'),
    min_people: selector(state, 'min_people'),
    language: selector(state, 'language'),
    dateSecondPaymentPhase: selector(state, 'dateSecondPaymentPhase'),
    blockDate: selector(state, 'blockDate'),
    contractDate: selector(state, 'contractDate'),
    paymentPhases: selector(state, 'paymentPhases'),
    bride: selector(state, 'bride'),
    groom: selector(state, 'groom'),
  }
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(CreateWeddingPage));
