import React from 'react';
import { Field, reduxForm, formValueSelector, change, initialize, FormSection } from 'redux-form';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import FileInput from './FileInput';
import { InputLabelDiv } from '../../components/inputs/InputStyles';
import { getCountryOptions, getCountryTranslation } from '../../pages/translations/Countries';
import { BillingUsersEnum } from './budgetUtils';
import { isValidPhoneNumber } from 'react-phone-number-input';

const BudgetTypes = [
  { _id: 'PAYMENT', name: 'Pagamentos Efectuados' },
  { _id: 'DISCOUNT', name: 'Valores a descontar' },
  { _id: 'EXPENSE', name: 'Valores a adicionar' },
  { _id: 'OFFER', name: 'Ofertas' },
  { _id: 'PAYMENT_PROOF', name: 'Comprovativos de pagamentos' }
];

const validations = values => {
  let error = {};

  if (values.type === 'PAYMENT') {
    error = FormValidator.make({
      type: 'required',
      phase: 'required',
      title: 'required',
      description: 'required',
      value: 'required',
      date: 'required'
    })(values);
  } else if (values.type === 'PAYMENT_PROOF') {
    error.billing = FormValidator.make({
      user: 'required',
      fullName: 'required',
      identificationNumber: 'required',
      // streetDoorNumber: 'required',
      // zipCode: 'required',
      // street: 'required',
      // country: 'required'
    })(values?.billing);

    const billingErrors = FormValidator.make({
      document: 'required',
      description: 'required',
    })(values);

    error = { ...error, ...billingErrors };
  } else {
    error = FormValidator.make({
      type: 'required',
      title: 'required',
      description: 'required',
      value: 'required',
      date: 'required'
    })(values);
  }

  return error;
};

let BudgetModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  wedding,
  budget,
  type,
  change,
  dispatch,
  budgetForm,
  initialValues
}) => {

  const aBudgetProof = budget
    .filter(b => b?.type === 'PAYMENT_PROOF')
    .map(b => ({
      _id: b._id,
      name: b.date.slice(0, 10) + ': ' + b.description,
      biling: {
        country: b.billing.country,
        fullName: b.billing.fullName,
        identificationNumber: b.billing.identificationNumber,
        street: b.billing.street,
        streetDoorNumber: b.billing.streetDoorNumber,
        zipCode: b.billing.zipCode
      }
    }));
  
  const onBillingUserSelect = (billingUser) => {
    const data = { ...budgetForm };
    const selectUser = [];
    selectUser.push(...wedding?.otherUserBillingList);
    selectUser.push(wedding.groom);
    selectUser.push(wedding.bride);
    selectUser.push(wedding?.otherUserBilling);
    data['billing'] = selectUser.find(user => user?.fullName == billingUser);

    dispatch(initialize('manage_budget_form', data));
    change('billing', data?.billing);
    change( 'userId', data['billing']?._id);
    change( 'userType', data['billing']?.user);
  }

  const getBillingUsers = () => {
    const billingUsers = [
      { _id: wedding?.groom?.fullName || wedding?.groom?.name, name: wedding?.groom?.fullName || wedding?.groom?.name, user: BillingUsersEnum.GROOM },
      { _id: wedding?.bride?.fullName || wedding?.bride?.name, name: wedding?.bride?.fullName || wedding?.bride?.name, user: BillingUsersEnum.BRIDE },
    ];

    if (wedding?.otherUserBillingList.length > 0){  
      wedding.otherUserBillingList.forEach(element => {
        billingUsers.push({ _id: element.fullName, name: element.fullName, user: BillingUsersEnum.OTHER });
      });
    }
    billingUsers.push({ _id: BillingUsersEnum.OTHER, name: 'OTHER' });
    return billingUsers;
  }


  return (
    <Modal
      visible={open}
      title={edit ? 'Editar registo' : 'Adicionar registo'}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          {!edit && <Field
            component={SelectInput}
            name={'type'}
            label={'Tipo de registo *'}
            placeholder={'Selecione o tipo de registo'}
            type="select"
            data={BudgetTypes}
          />}
          {type === 'PAYMENT' && <Field
            component={SelectInput}
            name={'phase'}
            label={'Fase do Pagamento *'}
            placeholder={'Selecione a fase'}
            type="select"
            data={wedding.paymentPhases.map(m => ({ name: `${m.phase.pt} ${m.order} (${moment(m.date).format('DD/MM/YYYY')})`, ...m }))}
            dataKey='order'
            onChange={(event) => {
              const selectedPaymentPhase = wedding.paymentPhases.find(f => f?.order && event && f.order == event);
              if (selectedPaymentPhase) {
                change('title', `${selectedPaymentPhase.phase.pt} ${selectedPaymentPhase.order} (${moment(selectedPaymentPhase.date).format('DD/MM/YYYY')})`);
              }
            }}
          />}
           {type === 'PAYMENT' && <Field
            component={SelectInput}
            name={'paymentProof'}
            label={'Comprovativo *'}
            placeholder={'Selecione o comprovativo'}
            type="select"
            data={aBudgetProof}     
          />}
          {type != 'PAYMENT_PROOF' && <Field
            component={TextInput}
            name={'title'}
            type="text"
            label={'Titulo *'}
            placeholder={'Titulo do registo'}
          />}

          {type != 'PAYMENT_PROOF' && <Field
            component={CurrencyInput}
            name={'value'}
            defaultValue="00.00"
            label={'Valor *'}
            placeholder={'Insira o valor do registo'}
          />}

          {type != 'PAYMENT_PROOF' && <Field
            component={DateInput}
            name="date"
            label="Data *"
            placeholder="Data do registo"
          />}

          {type === 'PAYMENT_PROOF' && <Field
            component={FileInput}
            name={'document'}
            label={'Upload do recibo *'}
            accept= {['image/png', 'image/jpeg', 'image/gif', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
          />}

          <Field
            component={TextAreaInput}
            name={'description'}
            type="text"
            label={type === 'PAYMENT_PROOF' ? 'Descrição' : 'Descrição *'}
            placeholder={'Descrição do registo'}
          />

          {type === 'PAYMENT' && <Field
            component={CheckboxInput}
            name={'phaseComplete'}
            type="text"
            label={'Pagamento da fase concluído?'}
            positiveLabel={'Sim'}
          />}

          {type === 'PAYMENT_PROOF' && <React.Fragment>
            <FormSection name='billing'>
              <Field
                component={SelectInput}
                name={'user'}
                type="select"
                label={'Utilizador de faturação *'}
                placeholder={'Utilizador de faturação'}
                data={getBillingUsers()}
                onChange={(billingUser) => onBillingUserSelect(billingUser)}
              />

              {budgetForm?.billing?.user && <React.Fragment>
                <Field
                  component={TextInput}
                  name={'fullName'}
                  type="text"
                  label={'Nome *'}
                  placeholder={'Nome'}
                />

                <Field
                  component={TextInput}
                  name={'identificationNumber'}
                  type="text"
                  label={'NIF *'}
                  placeholder={'Númer de identificação fiscal'}
                />

                <Field
                  component={TextInput}
                  name={'streetDoorNumber'}
                  type="text"
                  label={'Rua e número de porta'}
                  placeholder={'Rua e número de porta'}
                />

                <Field
                  component={TextInput}
                  name={'zipCode'}
                  type="text"
                  label={'Código postal'}
                  placeholder={'Código postal'}
                />

                <Field
                  component={TextInput}
                  name={'street'}
                  type="text"
                  label={'Freguesia e concelho'}
                  placeholder={'Freguesia e concelho'}
                />

                <Field
                  component={SelectInput}
                  name={'country'}
                  type="select"
                  label={'País'}
                  placeholder={'País'}
                  data={getCountryOptions()}
                  dataKey={'value'}
                  costumeLabel={(country) => getCountryTranslation(country)}
                />
              </React.Fragment>}
            </FormSection>
          </React.Fragment>}
        </BaseForm>
      )}
    </Modal>
  );
};


BudgetModal = reduxForm({
  form: 'manage_budget_form',
  validate: validations
})(BudgetModal);

const selector = formValueSelector('manage_budget_form');

const mapStateToProps = state => ({
  type: selector(state, 'type'),
  budgetForm: {
    _id: selector(state, '_id'),
    type: selector(state, 'type'),
    date: selector(state, 'date'),
    document: selector(state, 'document'),
    description: selector(state, 'description'),
    billing: {
      user: selector(state, 'billing.user')
    }
  }
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetModal);
